import GSAP from 'gsap'
import Component from 'classes/Component'

import { mapEach } from 'utils/dom'
import AsyncLoad from 'classes/AsyncLoad'

export default class Pieds extends Component {
  constructor ({ template }) {
    super({
      element: '.pieds',
      elements: {
        infos: '.pieds_infos_wrapper',
        mediaWhite: '.pieds_media_background_white',
        mediaBlack: '.pieds_media_background_black',
        images: '[data-src]'
      }
    })
    this.template = template
    this.onChange(this.template)
    this.imageLoaded()
  }

  /**
   * chargement images background
   */
  imageLoaded () {
    this.images = mapEach(this.elements.images, element => {
      return new AsyncLoad({
        element
      })
    })
  }

  onChange (template) {
    if (template === '/accueil' || template === '/nous-joindre' || template === '/') {
      GSAP.set(this.element, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.infos, {
        autoAlpha: 0
      })
      GSAP.set(this.elements.mediaBlack, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.mediaWhite, {
        autoAlpha: 0
      })
    } else if (template === '/error') {
      GSAP.set(this.element, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.infos, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.mediaBlack, {
        autoAlpha: 0
      })
      GSAP.set(this.elements.mediaWhite, {
        autoAlpha: 1
      })
    } else if (template === '/services/geologie' || template === '/services/sinistre' || template === '/services/environnement') {
      GSAP.set(this.element, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.infos, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.mediaBlack, {
        autoAlpha: 0
      })
      GSAP.set(this.elements.mediaWhite, {
        autoAlpha: 1
      })
    } else if (template === '/projets') {
      console.log('dans /projets')
      GSAP.set(this.element, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.infos, {
        autoAlpha: 1
      })
      GSAP.set(this.elements.mediaBlack, {
        autoAlpha: 0
      })
      GSAP.set(this.elements.mediaWhite, {
        autoAlpha: 0
      })
    }
  }
}
