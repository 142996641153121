import Page from 'classes/Page'

export default class extends Page {
  constructor () {
    super({
      id: 'projets',

      classes: {
        active: 'projets--active'
      },

      element: '.projets',
      elements: {
        title: '.projets_title',
        wrapper: '.projets_wrapper'
      }
    })
  }

  /**
   * Animations.
   */
  async show (url) {
    this.element.classList.add(this.classes.active)

    return super.show(url)
  }

  async hide (url) {
    this.element.classList.remove(this.classes.active)

    return super.hide(url)
  }
}
