import GSAP from 'gsap'
import Component from 'classes/Component'

import { COLOR_BLUE_BIOTERRA, COLOR_GRAY_BIOTERRA, COLOR_GREEN_SEA_BIOTERRA } from 'utils/colors'

export default class Entete extends Component {
  constructor ({ template }) {
    super({
      element: '.entete',
      elements: {
        barParallele: '.entete_bar_parallel',
        navigation: '.entete_navigation'
      }
    })

    this.onChange(template)
  }

  onChange (template) {
    if (template === '/accueil' || template === '/' || template === '/error') {
      GSAP.set(this.element, {
        color: COLOR_GRAY_BIOTERRA
      })
      GSAP.set(this.element, { autoAlpha: 1 })
      GSAP.set(this.elements.barParallele, { autoAlpha: 1 })
      GSAP.set(this.elements.navigation, { autoAlpha: 0 })
    } else if (template === '/services/environnement') {
      GSAP.set(this.element, {
        color: COLOR_GREEN_SEA_BIOTERRA
      })
      GSAP.set(this.element, { autoAlpha: 1 })
      GSAP.set(this.elements.barParallele, { autoAlpha: 1 })
      GSAP.set(this.elements.navigation, { autoAlpha: 1 })
    } else if (template === '/services/geologie') {
      GSAP.set(this.element, {
        color: COLOR_GREEN_SEA_BIOTERRA
      })
      GSAP.set(this.element, { autoAlpha: 1 })
      GSAP.set(this.elements.barParallele, { autoAlpha: 1 })
      GSAP.set(this.elements.navigation, { autoAlpha: 1 })
    } else if (template === '/services/sinistre') {
      GSAP.set(this.element, {
        color: COLOR_GREEN_SEA_BIOTERRA
      })
      GSAP.set(this.element, { autoAlpha: 1 })
      GSAP.set(this.elements.barParallele, { autoAlpha: 1 })
      GSAP.set(this.elements.navigation, { autoAlpha: 1 })
    } else if (template === '/nous-joindre') {
      GSAP.set(this.element, {
        color: COLOR_GRAY_BIOTERRA
      })
      GSAP.set(this.element, { autoAlpha: 1 })
      GSAP.set(this.elements.barParallele, { autoAlpha: 1 })

      GSAP.set(this.elements.navigation, { autoAlpha: 1 })
    } else if (template === '/projets') {
      GSAP.set(this.element, {
        color: COLOR_BLUE_BIOTERRA
      })
      GSAP.set(this.element, { autoAlpha: 1 })
      GSAP.set(this.elements.barParallele, { autoAlpha: 1 })
      GSAP.set(this.elements.navigation, { autoAlpha: 1 })
    } else {
      GSAP.set(this.element, { autoAlpha: 0 })
    }
  }
}
