import GSAP from 'gsap'

import Component from 'classes/Component'

export default class extends Component {
  constructor ({ element }) {
    super({
      classes: {
        active: 'card--active'
      },
      element,
      elements: {
        carousel: '.card_wrapper_caroussel',
        close: '.close-button'
      }
    })
    this.onResize()
  }

  /**
   * Animations.
  */
  animateIn () {
    this.timelineIn = GSAP.timeline({
      delay: 0.5
    })

    this.timelineIn.call(_ => {
      this.element.classList.add(this.classes.active)
    })
  }

  animateOut () {
    this.element.classList.remove(this.classes.active)
  }

  /**
   * Listeners.
   */
  onClose () {
    this.emit('close')
  }

  /**
   * Events.
   */
  onResize () {
    this.bounds = this.elements.carousel.getBoundingClientRect()
  }

  /**
   * Listeners.
   */
  addEventListeners () {
    this.elements.close.addEventListener('click', this.onClose)
  }
}
