import GSAP from 'gsap'

import { Curtains, Plane } from 'curtainsjs'
import axios from 'axios'

export default class {
  constructor () {
    this.canvasElement = document.getElementById('canvas') // Sélectionne le canvas généré à l'intérieur de #canvas
    this.planeElement = document.querySelector('.accueil_plane_container')
    this.caroussel = document.querySelector('.accueil_caroussel_wrapper')

    this.imageTitre = document.querySelector('.imageTitre')

    // Initialisation de Curtains après le chargement des shaders
    this.curtains = new Curtains({
      container: document.getElementById('canvas'),
      autoResize: true
    })
    this.isMouseOver = false
    this.frameCounter = 0
    this.shouldLerp = false
    this.lerpFrames = 0
    this.plane = null
    this.lerp = this.lerp.bind(this)
    this.triggerLerpToDefault = this.triggerLerpToDefault.bind(this)
    this.trackMouseFrames = this.trackMouseFrames.bind(this)
    this.createTimeLineIn()
    this.animeBioterra()
    this.show()
  }

  /*
  * Animations.
  */

  createTimeLineIn () {
    const tl = GSAP.timeline({ delay: 3 })

    tl.fromTo(this.canvasElement, { scale: 1.2, x: '22rem', y: '0.5rem' }, { scale: 1, x: '0px', y: '0px', duration: 2 })
      .fromTo(this.caroussel, { y: '1000px' }, { y: '0px', duration: 3, delay: 2 }, '-=4')

    GSAP.timeline({ delay: 2 }).fromTo(this.imageTitre, { scale: 1.2, x: '22rem', y: '0.5rem' }, { scale: 1, x: '0px', y: '0px', duration: 2 })

    return tl
  }

  show () {
    GSAP.set(this.canvasElement, { opacity: 1 })
  }

  hide () {
    GSAP.set(this.canvasElement, { opacity: 0 })
  }

  /*
  *Events
  */

  lerp (start, end, t) {
    return start * (1 - t) + end * t
  }

  triggerLerpToDefault () {
    if (this.plane) {
      this.plane.uniforms.uMouseStart.value = [...this.plane.uniforms.uMouse.value]
      this.plane.uniforms.uMouseTarget.value = [10.0, 10.0]
      this.shouldLerp = true
      this.lerpFrames = 0
    }
  }

  trackMouseFrames () {
    if (this.isMouseOver) {
      this.frameCounter++
      window.requestAnimationFrame(this.trackMouseFrames)
    }
  }

  animeBioterra () {
    // Utilisez axios.all pour vous assurer que les deux shaders sont chargés avant de continuer
    axios.all([
      axios.get('app/shaders/accueil-vertex.glsl'),
      axios.get('app/shaders/accueil-fragment.glsl')
    ]).then(axios.spread((vertexResponse, fragmentResponse) => {
      document.getElementById('accueil-vertex').textContent = vertexResponse.data
      document.getElementById('accueil-fragment').textContent = fragmentResponse.data

      this.plane = new Plane(this.curtains, this.planeElement, {
        vertexShader: document.getElementById('accueil-vertex').textContent,
        fragmentShader: document.getElementById('accueil-fragment').textContent,
        uniforms: {
          uMouse: { name: 'uMouse', type: '2f', value: [10.0, 10.0] },
          uMouseStart: { name: 'uMouseStart', type: '2f', value: [10.0, 10.0] },
          uMouseTarget: { name: 'uMouseTarget', type: '2f', value: [10.0, 10.0] }
        }
      })

      // Ajoutez ici la boucle de rendu
      this.plane.onRender(() => {
        if (this.shouldLerp && this.lerpFrames <= 30) {
          const lerpFactor = this.lerpFrames / 30

          this.plane.uniforms.uMouse.value[0] = this.lerp(this.plane.uniforms.uMouseStart.value[0], this.plane.uniforms.uMouseTarget.value[0], lerpFactor)
          this.plane.uniforms.uMouse.value[1] = this.lerp(this.plane.uniforms.uMouseStart.value[1], this.plane.uniforms.uMouseTarget.value[1], lerpFactor)

          this.lerpFrames++
        } else if (this.lerpFrames > 30) {
          this.shouldLerp = false
          this.lerpFrames = 0
        }
      })

      this.canvasElement.addEventListener('mousemove', (e) => {
        this.trackMouseFrames()
        // Calculer les coordonnées de la souris par rapport à l'élément plane
        const x = -(((e.clientX - this.planeBoundingRect.left) / this.planeElementClientW) * 2 - 1)
        let y = -(((e.clientY - this.planeBoundingRect.top) / this.planeElementClientH) * -2 + 1)
        // console.log('e.clientY:', e.clientY, 'this.planeBoundingRect.top:', this.planeBoundingRect.top, 'this.planeElementClientH:', this.planeElementClientH)
        // console.log('y:', y)
        // Clamper la valeur de y entre -1 et 1 pour éviter tout débordement
        y = Math.max(-1, Math.min(1, y))
        this.plane.uniforms.uMouse.value = [x, y]

        this.plane.uniforms.uMouseStart.value = [x, y]
      })

      this.canvasElement.addEventListener('mouseenter', () => {
        this.isMouseOver = true
        this.frameCounter = 0
        this.trackMouseFrames()
      })

      this.canvasElement.addEventListener('mouseout', () => {
        this.isMouseOver = false
        this.triggerLerpToDefault()
      })

      this.createTimeLineIn()
    })).catch(error => {
      console.error('Erreur lors du chargement des shaders:', error)
    })
  }

  onResize () {
    this.planeBoundingRect = this.canvasElement.getBoundingClientRect()
    this.planeElementClientW = this.planeElement.clientWidth
    this.planeElementClientH = this.planeElement.clientHeight
  }

  /*
  *Loop
  */
  update () {
  }
}
