import { Plane, Raycast, Transform, Vec2 } from 'ogl'
import Prefix from 'prefix'
import GSAP from 'gsap'
import Media from './Media'
import map from 'lodash/map'
import Detection from 'classes/Detection'

import { getOffset, mapEach } from 'utils/dom'

export default class {
  constructor ({ camera, gl, renderer, scene, sizes }) {
    this.id = 'joindre'

    this.camera = camera
    this.gl = gl
    this.renderer = renderer
    this.scene = scene
    this.sizes = sizes

    this.transformPrefix = Prefix('transform')
    this.group = new Transform()
    this.mouse = new Vec2()

    this.joindreElement = document.querySelector('.joindre')

    this.galleryElement = document.querySelector('.joindre_wrapper_caroussel')
    this.joindreElementsLinks = document.querySelectorAll('.joindre_card_link')
    this.mediasElements = document.querySelectorAll('.joindre_card_item_image')

    this.cardElements = document.querySelectorAll('.card')
    this.entete = document.querySelector('.entete')
    this.template = window.location.pathname

    this.x = {
      current: 0,
      target: 0,
      lerp: 0.1
    }

    this.y = {
      current: 0,
      target: 0,
      lerp: 0.1
    }

    this.scrollCurrent = {
      x: 0,
      y: 0
    }

    this.scroll = {
      x: 0,
      y: 0,
      current: 0,
      start: 0,
      target: 0,
      lerp: 0.1,
      velocity: 1
    }

    this.speed = {
      current: 0,
      target: 0,
      lerp: 0.1
    }

    this.createRaycast()
    this.createGeometry()
    this.createGallery()

    this.group.setParent(this.scene)

    this.show()

    setTimeout(() => {
      this.simulateMouseMove(this.scroll)
      // console.log('this.scroll envi:', this.scroll)
    }, 1000)
  }

  detectionUrl () {
    if (this.template) {
      this.entete.style.display = 'none'
    }
  }

  simulateMouseMove () {
    // Définissez les coordonnées cibles pour simuler le mouvement
    this.x.current = 0
    this.x.lerp = 0.1
    this.x.target = -170
    this.update()
  }

  createRaycast () {
    this.raycast = new Raycast(this.gl)
  }

  createGeometry () {
    this.geometry = new Plane(this.gl, {
      widthSegments: 20,
      heightSegments: 20
    })
  }

  createGallery () {
    this.medias = map(this.mediasElements, (element, index) => {
      const media = new Media({
        card: this.cardElements[index],
        element,
        geometry: this.geometry,
        index,
        gl: this.gl,
        scene: this.group,
        sizes: this.sizes
      })

      media.on('open', this.onOpen.bind(this))
      media.on('close', this.onClose.bind(this))

      return media
    })
    this.mediasMeshes = mapEach(this.medias, media => media.mesh)
  }

  /*
  * Animations.
  */

  async show () {
    this.isVisible = true
    this.group.setParent(this.scene)
    map(this.medias, media => media.show())
  }

  hide () {
    document.body.style.cursor = ''

    this.isVisible = false
    this.group.setParent(null)
    map(this.medias, media => media.hide())
  }

  /**
  * Listeners.
  */
  onOpen (index) {
    this.isVisible = false

    this.joindreElement.classList.add('joindre--open')
    this.group.setParent(null)
    mapEach(this.medias, (media, mediaIndex) => {
      if (mediaIndex === index) {
        media.hide()
      } else {
        media.hide()
      }
    })

    this.detectionUrl()
  }

  onClose () {
    this.isVisible = true

    this.joindreElement.classList.remove('joindre--open')

    this.entete.style.display = 'block'

    this.group.setParent(this.scene)
    mapEach(this.medias, media => {
      media.show()
    })
  }

  /*
  *Events
  */

  onResize (event) {
    this.sizes = event.sizes

    this.galleryBounds = this.galleryElement.getBoundingClientRect()

    this.gallerySizes = {
      width: this.galleryBounds.width / window.innerWidth * this.sizes.width,
      height: this.galleryBounds.height / window.innerHeight * this.sizes.height
    }

    map(this.medias, media => media.onResize(event, this.scroll))

    mapEach(this.joindreElementsLinks, (element, elementIndex) => {
      element.bounds = getOffset(element)
    })
  }

  onTouchDown ({ x, y }) {
    if (!this.isVisible) return
    this.isDown = true
    this.speed.target = 0
    this.scroll.last = this.scroll.current
  }

  onTouchMove ({ x, y }) {
    if (!this.isVisible) return

    // Réglez la hauteur du "zone de détection" en pourcentage
    const detectionHeightPercentage = !Detection.isPhone() ? 0.7 : 0.8 // 0.7 equivaut 70%

    // Calculez la hauteur de la zone de détection en pixels
    const detectionHeight = this.renderer.height * detectionHeightPercentage

    // Calculez la position Y de la souris en fonction de la zone de détection
    const mouseYInDetectionZone = y.end - (this.renderer.height - detectionHeight)

    // La souris est dans la zone de détection
    this.mouse.set(
      2.0 * (x.end / this.renderer.width) - 1.0,
      2.0 * (1.0 - mouseYInDetectionZone / detectionHeight) - 1.0
    )

    this.raycast.castMouse(this.camera, this.mouse)

    const [hit] = this.raycast.intersectBounds(this.mediasMeshes)

    if (hit && hit.index !== this.index) {
      document.body.style.cursor = 'pointer'
    } else if (!hit) {
      document.body.style.cursor = ''
    }

    this.hit = hit ? hit.index : null

    if (!this.isDown) return

    const distanceX = x.end - x.start
    const distanceY = y.end - y.start

    // Mettre à jour les cibles x et y de la même manière que dans onWheel
    this.x.target += distanceX * 0.05
    this.y.target += distanceY * 0.05
    this.speed.target = 1
  }

  onTouchUp ({ x, y }) {
    if (!this.isVisible) return

    this.isDown = false

    const [hit] = this.raycast.intersectBounds(this.mediasMeshes)

    if (hit && hit.index !== this.index) {
      this.medias[hit.index].animateIn()
    }
    this.speed.target = 0
  }

  onWheel (event) {
    if (!this.isVisible) return
    this.x.target += event.pixelY * 0.5
    this.y.target += event.pixelY * 0.5

    this.speed.target = 1
    this.scrollCurrent.x = this.scroll.x
    this.scrollCurrent.y = this.scroll.y
    // Réinitialisez les transformations après un délai sans défilement
    clearTimeout(this.resetTimeout)
    this.resetTimeout = setTimeout(() => {
      this.speed.target = 0
    }, 200) // Ajustez la durée du délai
  }

  ontouchstart (event) {
    if (!this.isVisible) return
    this.isDown = true
    this.speed.target = 1
  }

  ontouchmove (event) {
    if (!this.isVisible) return
    if (!this.isDown) return
    this.x.target += (event.y.start - event.y.end) * 1
    this.y.target += (event.y.end - event.y.start) * 0.05
    this.speed.target = 1
  }

  ontouchend (event) {
    if (!this.isVisible) return
    this.isDown = false
    this.speed.target = 0
  }

  /*
  *Loop
  */
  update () {
    if (!this.galleryBounds) return

    this.speed.current = GSAP.utils.interpolate(this.speed.current, this.speed.target, this.speed.lerp)

    this.x.current = GSAP.utils.interpolate(this.x.current, this.x.target, this.x.lerp)
    this.y.current = GSAP.utils.interpolate(this.y.current, this.y.target, this.y.lerp)

    // this.scroll.target = GSAP.utils.interpolate(this.y.current, this.y.target, this.y.lerp)

    this.scroll.current = GSAP.utils.interpolate(this.scroll.current, this.scroll.target, this.scroll.lerp)

    if (this.scroll.x < this.x.current) {
      this.x.direction = 'right'
    } else if (this.scroll.x > this.x.current) {
      this.x.direction = 'left'
    }

    if (this.scroll.y < this.y.current) {
      this.y.direction = 'top'
    } else if (this.scroll.y > this.y.current) {
      this.y.direction = 'bottom'
    }

    this.scroll.x = this.x.current
    this.scroll.y = this.y.current

    this.scroll.last = this.scroll.current

    const index = Math.floor(Math.abs((this.scroll.current - (this.medias[0].bounds.width / 2)) / this.scroll.limit) * (this.medias.length - 1))

    map(this.medias, (media, mediaIndex) => {
      mediaIndex = index
      const scaleX = media.mesh.scale.x / 2
      const scaleY = media.mesh.scale.y / 2

      const offsetY = this.sizes.height / 2
      const offsetX = this.sizes.width / 2

      if (this.x.direction === 'left') {
        const x = media.mesh.position.x + scaleX
        if (x < -offsetX) {
          media.extra.x += this.gallerySizes.width * 1.67
        }
      } else if (this.x.direction === 'right') {
        const x = media.mesh.position.x - scaleX
        if (x > offsetX) {
          media.extra.x -= this.gallerySizes.width * 1.67
        }
      }

      if (this.y.direction === 'top') {
        const y = media.mesh.position.y + scaleY
        if (y < -offsetY) {
          media.extra.y += this.gallerySizes.height * 1.67
        }
      } else if (this.y.direction === 'bottom') {
        const y = media.mesh.position.y - scaleY
        if (y > offsetY) {
          media.extra.y -= this.gallerySizes.height * 1.67
        }
      }
      media.update(this.scroll, mediaIndex, this.speed.current)
    })
  }

  /*
  ** Destroy
  */

  destroy () {
    this.scene.removeChild(this.group)
  }
}
