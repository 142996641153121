import { Mesh, Program } from 'ogl'
import GSAP from 'gsap'

import vertex from 'shaders/paper-vertex.glsl'
import fragment from 'shaders/paper-fragment.glsl'

export default class {
  constructor ({ element, geometry, gl, index, scene, sizes }) {
    this.element = element
    this.geometry = geometry
    this.gl = gl
    this.index = index
    this.scene = scene
    this.sizes = sizes

    this.extra = {
      x: 0,
      y: 0
    }

    this.createTexture()
    this.createProgram()
    this.createMesh()
  }

  createTexture () {
    this.texture = window.TEXTURES[this.element.getAttribute('data-src')]
  }

  createProgram () {
    this.program = new Program(this.gl, {
      fragment,
      vertex,
      uniforms: {
        uAlpha: { value: 0 },
        uSpeed: { value: 0 },
        uViewportSizes: { value: [this.sizes.width, this.sizes.height] },
        tMap: { value: this.texture }
      }
    })
  }

  createMesh () {
    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program
    })

    this.mesh.setParent(this.scene)

    this.mesh.scale.x = 2

    this.mesh.position.x += this.index * this.mesh.scale.x
  }

  createBounds ({ sizes }) {
    this.sizes = sizes
    this.bounds = this.element.getBoundingClientRect()

    this.updateScale()
    this.updateX()
    this.updateY()
  }

  /*
  * Animations.
  */

  show () {
    GSAP.fromTo(this.program.uniforms.uAlpha, {
      value: 0
    }, {
      value: 1 // pour opacité complete
    })
  }

  hide () {
    GSAP.to(this.program.uniforms.uAlpha, {
      value: 0
    })
  }

  /*
  *Events
  */

  onResize (sizes, scroll) {
    this.extra = {
      x: 0,
      y: 0
    }

    this.scroll = {
      x: 0,
      y: 0
    }
    this.createBounds(sizes)

    if (window.innerWidth <= 1024) {
      this.updateY(scroll.y)
    } else {
      this.updateX(scroll.x)
    }
  }

  /*
  *Loop
  */

  updateScale () {
    this.height = this.bounds.height / window.innerHeight
    this.width = this.bounds.width / window.innerWidth

    this.mesh.scale.x = this.sizes.width * this.width
    this.mesh.scale.y = this.sizes.height * this.height

    // console.log(this.mesh.position.x)
  }

  updateX (x = 0) {
    this.x = (this.bounds.left + x) / window.innerWidth
    this.mesh.position.x = (-this.sizes.width / 2) + (this.mesh.scale.x / 2) + (this.x * this.sizes.width) + this.extra.x
  }

  updateY (y = 0) {
    this.y = (this.bounds.top + y) / window.innerHeight
    this.mesh.position.y = (this.sizes.height / 2) - (this.mesh.scale.y / 2) - (this.y * this.sizes.height) + this.extra.y
  }

  update (scroll, speed) {
    if (!this.bounds) return

    if (window.innerWidth <= 1024) {
      this.updateY(scroll ? scroll.y : 0)
    } else {
      this.updateX(scroll ? scroll.x : 0)
    }
    this.program.uniforms.uSpeed.value = speed
  }
}
