import { Mesh, Program, Transform } from 'ogl'
import GSAP from 'gsap'

import Component from 'classes/Component'

import vertex from 'shaders/paper-vertex.glsl'
import fragment from 'shaders/paper-fragment.glsl'

import MediaDOM from './MediaDOM'

export default class extends Component {
  constructor ({ card, element, geometry, gl, index, scene, sizes }) {
    super({
      element,
      elements: {
        image: '.joindre_card_item_image'
      }
    })
    this.card = card
    this.geometry = geometry
    this.gl = gl
    this.index = index
    this.scene = scene
    this.sizes = sizes

    this.extra = {
      x: 0,
      y: 0
    }

    this.opacity = {
      current: 0,
      target: 0,
      lerp: 0.1,
      multiplier: 0
    }

    this.group = new Transform()
    this.frame = 0

    this.createCard()
    this.createTexture()
    this.createProgram()
    this.createMesh()

    this.group.setParent(this.scene)
  }

  createCard () {
    this.cardDOM = new MediaDOM({
      element: this.card
    })
    this.cardDOM.on('close', this.animateOut.bind(this))
  }

  createTexture () {
    this.texture = window.TEXTURES[this.element.getAttribute('data-src')]
  }

  createProgram () {
    this.program = new Program(this.gl, {
      fragment,
      vertex,
      uniforms: {
        uAlpha: { value: 0 },
        uSpeed: { value: 0 },
        uViewportSizes: { value: [this.sizes.width, this.sizes.height] },
        tMap: { value: this.texture }
      }
    })
  }

  createMesh () {
    this.mesh = new Mesh(this.gl, {
      geometry: this.geometry,
      program: this.program
    })
    this.mesh.scale.x = 2

    this.mesh.position.x += this.index * this.mesh.scale.x
    if (this.index === 1) {
      this.mesh.index = this.index
    }
    this.mesh.setParent(this.scene)
  }

  createBounds ({ sizes }) {
    this.sizes = sizes
    this.bounds = this.element.getBoundingClientRect()

    this.updateScale()
    this.updateX()
    this.updateY()
  }

  /*
  * Animations.
  */

  show () {
    GSAP.fromTo(this.program.uniforms.uAlpha, {
      value: 0
    }, {
      value: 1 // pour opacité complete
    })
    GSAP.to(this.opacity, {
      delay: 0.5,
      multiplier: 1
    })
  }

  hide () {
    GSAP.to(this.program.uniforms.uAlpha, {
      value: 0
    })
    GSAP.to(this.opacity, {
      multiplier: 0
    })

    this.cardDOM.animateOut()
  }

  /*
  *Events
  */

  onResize (sizes, scroll) {
    this.cardDOM.onResize()
    this.extra = {
      x: 0,
      y: 0
    }

    this.scroll = {
      x: 0,
      y: 0
    }
    this.createBounds(sizes)

    if (window.innerWidth <= 1024) {
      this.updateY(scroll.y)
    } else {
      this.updateX(scroll.x)
    }
  }

  /**
   * Animations.
   */
  animateIn () {
    GSAP.to(this, {
      animation: 9,
      duration: 3,
      ease: 'expo.inOut'
    })

    this.cardDOM.animateIn()

    this.emit('open', this.index)
  }

  animateOut () {
    GSAP.to(this, {
      animation: 0,
      duration: 3,
      ease: 'expo.inOut'
    })

    this.cardDOM.animateOut()

    this.emit('close', this.index)
  }

  /*
  *Loop
  */

  updateScale () {
    this.height = this.bounds.height / window.innerHeight
    this.width = this.bounds.width / window.innerWidth

    this.mesh.scale.x = this.sizes.width * this.width
    this.mesh.scale.y = this.sizes.height * this.height

    // console.log(this.mesh.position.x)
  }

  updateX (x = 0) {
    this.x = (this.bounds.left + x) / window.innerWidth
    this.mesh.position.x = (-this.sizes.width / 2) + (this.mesh.scale.x / 2) + (this.x * this.sizes.width) + this.extra.x
  }

  updateY (y = 0) {
    this.y = (this.bounds.top + y) / window.innerHeight
    this.mesh.position.y = (this.sizes.height / 2) - (this.mesh.scale.y / 2) - (this.y * this.sizes.height) + this.extra.y
  }

  update (scroll, index, speed) {
    if (!this.bounds) return

    if (window.innerWidth <= 1024) {
      this.updateY(scroll ? scroll.y : 0)
    } else {
      this.updateX(scroll ? scroll.x : 0)
    }
    this.program.uniforms.uSpeed.value = speed
    this.opacity.target = index === this.index ? 1 : 1

    this.opacity.current = GSAP.utils.interpolate(this.opacity.current, this.opacity.target, this.opacity.lerp)
    this.mesh.program.uniforms.uAlpha.value = this.opacity.multiplier * this.opacity.current

    this.frame += 1
  }
}
