import NormalizeWheel from 'normalize-wheel'
import GSAP from 'gsap'
import each from 'lodash/each'

import Canvas from 'components/Canvas'
import Menu from 'components/Menu'
import Entete from 'components/Entete'
import Pieds from 'components/Pieds'
import Preloader from 'components/Preloader'
import Transition from 'components/Transition'

import Accueil from 'pages/Accueil'
import Environnement from 'pages/Environnement'
import Error from 'pages/Error'
import Geologie from 'pages/Geologie'
import Joindre from 'pages/Joindre'
import Projets from 'pages/Projets'
import Sinistre from 'pages/Sinistre'

class App {
  constructor () {
    this.template = window.location.pathname
    console.log(window.innerWidth, window.innerHeight)
    this.createCanvas()
    this.createPreloader()
    this.createTransition()
    this.createMenu()
    this.createEntete()
    this.createPieds()
    this.createPages()
    this.showMenu()
    this.hideMenu()
    this.survolItemMenu()
    this.MouseMove()
    this.addEventListeners()
    this.addLinkListeners()

    this.onResize()

    this.update()
  }

  createMenu () {
    this.menu = new Menu()
    GSAP.set(this.menu.element, { opacity: 1 })
  }

  createEntete () {
    this.entete = new Entete({
      template: this.template
    })
  }

  createPieds () {
    this.pieds = new Pieds({
      template: this.template
    })
  }

  createPreloader () {
    this.preloader = new Preloader({
      canvas: this.canvas
    })

    this.preloader.once('completed', this.onPreloaded.bind(this))
  }

  createCanvas () {
    this.canvas = new Canvas({
      template: this.template
    })
  }

  createTransition () {
    this.transition = new Transition()
  }

  createPages () {
    this.accueil = new Accueil()
    this.environnement = new Environnement()
    this.error = new Error()
    this.geologie = new Geologie()
    this.joindre = new Joindre()
    this.projets = new Projets()
    this.sinistre = new Sinistre()

    this.pages = {
      '/': this.accueil,
      '/accueil': this.accueil,
      '/error': this.error,
      '/services/environnement': this.environnement,
      '/services/geologie': this.geologie,
      '/services/sinistre': this.sinistre,
      '/nous-joindre': this.joindre,
      '/projets': this.projets
    }

    this.page = this.pages[this.template]
  }

  /**
   * Events.
   */

  showMenu () {
    const barParallelEntete = document.querySelector('.entete_bar_parallel')
    barParallelEntete.onclick = event => {
      event.preventDefault()
      document.querySelector('.menu_overlay_wrapper').style.width = '100%'
    }
  }

  hideMenu () {
    const closeBtn = document.querySelector('.menu_closebtn')

    closeBtn.onclick = event => {
      event.preventDefault()
      document.querySelector('.menu_overlay_wrapper').style.width = '0%'
    }
  }

  survolItemMenu () {
    // Affiche la flèche à côté de l'élément survolé
    function showArrow (element) {
      const menuBlockServices = document.querySelector('.menu_overlay_link_services')
      const submenu = document.querySelector('.submenu_services')
      const arrow = element.querySelector('.menu_fleche')

      if (element.querySelector('.menu_accueil') || element.querySelector('.menu_projets') || element.querySelector('.menu_contact')) {
        arrow.style.display = 'block'
      } else if (element.querySelector('.menu_services')) {
        arrow.style.display = 'block'
        submenu.style.display = 'block'
        if (window.innerWidth <= 768) {
          menuBlockServices.style.marginBottom = '18rem'
        } else {
          menuBlockServices.style.marginBottom = '7rem'
        }
      }
    }

    // Cache la flèche lorsque la souris quitte l'élément
    function hideArrow (element) {
      const menuBlockServices = document.querySelector('.menu_overlay_link_services')
      const submenu = element.querySelector('.submenu_services')
      const arrow = element.querySelector('.menu_fleche')

      if (element.querySelector('.menu_accueil') || element.querySelector('.menu_projets') || element.querySelector('.menu_contact')) {
        arrow.style.display = 'none'
      } else if (element.querySelector('.menu_services')) {
        arrow.style.display = 'none'
        submenu.style.display = 'none'
        menuBlockServices.style.marginBottom = '0rem'
      }
    }

    // Récupère tous les éléments du menu et ajoute les événements
    const menuItems = document.querySelectorAll('.menu_overlay_link')
    menuItems.forEach((menuItem) => {
      menuItem.addEventListener('mouseover', () => showArrow(menuItem))
      menuItem.addEventListener('mouseout', () => hideArrow(menuItem))
    })
  }

  MouseMove () {
    const cursor = document.querySelector('.cursor')

    let posX = 0
    let posY = 0
    let mouseX = 0
    let mouseY = 0

    const TweenMax = require('gsap').TweenMax

    const easing = 0 // Facteur d'atténuation du mouvement
    const offsetX = 50
    const offsetY = 70

    TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: function () {
        const diffX = mouseX - posX
        const diffY = mouseY - posY

        posX += diffX * easing
        posY += diffY * easing

        const adjustedX = mouseX - offsetX
        const adjustedY = mouseY - offsetY

        TweenMax.set(cursor, {
          css: {
            left: adjustedX,
            top: adjustedY
          }
        })
      }
    })

    // Sélectionnez l'élément document pour l'écouteur d'événement "mousemove"
    const documentElement = document.documentElement

    // Déclarez les constantes pour les éléments ".accueil_card_item_image"
    const images = document.querySelectorAll('.accueil_card_item_image')

    // Fonction de gestionnaire d'événement pour "mousemove"
    function handleMouseMove (e) {
      mouseX = e.pageX
      mouseY = e.pageY
    }

    // Fonction de gestionnaire d'événement pour "mouseenter"
    function handleMouseEnter () {
      cursor.classList.add('active')
      // follower.classList.add('active')
    }

    // Fonction de gestionnaire d'événement pour "mouseleave"
    function handleMouseLeave () {
      cursor.classList.remove('active')
      // follower.classList.remove('active')
    }

    // Ajoutez l'écouteur d'événement "mousemove" à l'élément document
    documentElement.addEventListener('mousemove', handleMouseMove)

    // Parcourez tous les éléments ".accueil_card_item_image" et ".services_card_itemm_image" puis ajoutez les écouteurs d'événement appropriés
    images.forEach(function (item) {
      item.addEventListener('mouseenter', handleMouseEnter)
      item.addEventListener('mouseleave', handleMouseLeave)
    })
  }

  onPreloaded () {
    this.onResize()

    this.canvas.onPreloaded()

    this.page.show()
  }

  onPopState () {
    this.onChange({
      url: window.location.pathname,
      push: false
    })
  }

  async onChange ({ url, push = true }) {
    url = url.replace(window.location.origin, '')

    const page = this.pages[url]

    await this.transition.show({
      color: page.element.getAttribute('data-color')
    })

    if (push) {
      window.history.pushState({}, '', url)
    }

    this.template = window.location.pathname

    this.page.hide()

    this.pieds.onChange(this.template)
    this.entete.onChange(this.template)
    this.canvas.onChange(this.template)

    this.page = page
    this.page.show()

    this.onResize()

    this.transition.hide()
    this.createMenu()
    document.querySelector('.menu_overlay_wrapper').style.width = '0%'
  }

  onResize () {
    if (this.page && this.page.onResize) {
      this.page.onResize()
    }

    window.requestAnimationFrame(_ => {
      if (this.canvas && this.canvas.onResize) {
        this.canvas.onResize()
      }
    })
  }

  onTouchDown (event) {
    if (this.canvas && this.canvas.onTouchDown) {
      this.canvas.onTouchDown(event)
    }

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchDown(event)
    }
  }

  onTouchMove (event) {
    if (this.canvas && this.canvas.onTouchMove) {
      this.canvas.onTouchMove(event)
    }

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchMove(event)
    }
  }

  onTouchUp (event) {
    if (this.canvas && this.canvas.onTouchUp) {
      this.canvas.onTouchUp(event)
    }

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchUp(event)
    }
  }

  onWheel (event) {
    const normalizedWheel = NormalizeWheel(event)

    if (this.canvas && this.canvas.onWheel) {
      this.canvas.onWheel(normalizedWheel)
    }

    if (this.page && this.page.onWheel) {
      this.page.onWheel(normalizedWheel)
    }
  }

  ontouchstart (event) {
    if (this.canvas && this.canvas.ontouchstart) {
      this.canvas.ontouchstart(event)
    }

    if (this.page && this.page.ontouchstart) {
      this.page.ontouchstart(event)
    }
  }

  ontouchmove (event) {
    if (this.canvas && this.canvas.ontouchmove) {
      this.canvas.ontouchmove(event)
    }

    if (this.page && this.page.ontouchmove) {
      this.page.ontouchmove(event)
    }
  }

  ontouchend (event) {
    if (this.canvas && this.canvas.ontouchend) {
      this.canvas.ontouchend(event)
    }

    if (this.page && this.page.ontouchend) {
      this.page.ontouchend(event)
    }
  }

  /**
   * Loop.
   */
  update () {
    if (this.page && this.page.update) {
      this.page.update()
    }

    if (this.canvas && this.canvas.update) {
      this.canvas.update(this.page.scroll)
    }

    if (this.canvasElement) {
      this.planeBoundingRect = this.canvasElement.getBoundingClientRect()
    }

    this.frame = window.requestAnimationFrame(this.update.bind(this))
  }

  /***
   * Listeners.
   */
  addEventListeners () {
    window.addEventListener('popstate', this.onPopState.bind(this))
    window.addEventListener('mousewheel', this.onWheel.bind(this))

    window.addEventListener('mousedown', this.onTouchDown.bind(this))
    window.addEventListener('mousemove', this.onTouchMove.bind(this))
    window.addEventListener('mouseup', this.onTouchUp.bind(this))

    window.addEventListener('touchstart', this.ontouchstart.bind(this))
    window.addEventListener('touchmove', this.ontouchmove.bind(this))
    window.addEventListener('touchend', this.ontouchend.bind(this))

    window.addEventListener('wheel', this.onWheel.bind(this))

    window.addEventListener('resize', this.onResize.bind(this))
  }

  addLinkListeners () {
    const links = document.querySelectorAll('.link')

    each(links, link => {
      const isLocal = link.href.indexOf(window.location.origin) > -1

      const isNotEmail = link.href.indexOf('mailto') === -1
      const isNotPhone = link.href.indexOf('tel') === -1

      if (isLocal) {
        link.onclick = event => {
          event.preventDefault()

          this.onChange({
            url: link.href
          })
        }

        // link.onmouseenter = event => this.onLinkMouseEnter(link)
        // link.onmouseleave = event => this.onLinkMouseLeave(link)
      } else if (isNotEmail && isNotPhone) {
        link.rel = 'noopener'
        link.target = '_blank'
      }
    })
  }
}

// eslint-disable-next-line no-new
new App()
