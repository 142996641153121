import { Camera, Renderer, Transform } from 'ogl'

import Accueil from './Accueil'
import Environnement from './Environnement'
import Geologie from './Geologie'
import Joindre from './Joindre'
import Sinistre from './Sinistre'

export default class Canvas {
  constructor ({ template }) {
    this.template = template

    this.x = {
      start: 0,
      distance: 0,
      end: 0
    }

    this.y = {
      start: 0,
      distance: 0,
      end: 0
    }

    this.createRenderer()
    this.createCamera()
    this.createScene()

    this.onResize()
  }

  createRenderer () {
    this.renderer = new Renderer({
      alpha: true,
      antialias: true,
      dpr: window.devicePixelRatio
    })

    this.gl = this.renderer.gl

    document.body.appendChild(this.gl.canvas)
  }

  createCamera () {
    this.camera = new Camera(this.gl)
    this.camera.position.z = 5
  }

  createScene () {
    this.scene = new Transform()
  }

  /**
   * Accueil.
   */
  createAccueil () {
    this.accueil = new Accueil({
      gl: this.gl,
      scene: this.scene,
      sizes: this.sizes
    })
  }

  /**
   * Environnement.
   */
  createEnvironnement () {
    this.environnement = new Environnement({
      gl: this.gl,
      scene: this.scene,
      sizes: this.sizes
    })
  }

  /**
   * Geologie.
   */
  createGeologie () {
    this.geologie = new Geologie({
      gl: this.gl,
      scene: this.scene,
      sizes: this.sizes
    })
  }

  /**
   * Joindre.
   */
  createJoindre () {
    this.joindre = new Joindre({
      camera: this.camera,
      gl: this.gl,
      renderer: this.renderer,
      scene: this.scene,
      sizes: this.sizes
    })
  }

  /**
   * Sinistre.
   */
  createSinistre () {
    this.sinistre = new Sinistre({
      gl: this.gl,
      scene: this.scene,
      sizes: this.sizes
    })
  }

  /**
   * Events.
   */
  onPreloaded () {
    this.createAccueil()
    this.createEnvironnement()
    this.createGeologie()
    this.createJoindre()
    this.createSinistre()

    this.onChange(this.template, true)
  }

  onChange (template, isPreloaded) {
    if (template === '/' || template === '/accueil') {
      this.accueil.show(isPreloaded)
    } else {
      this.accueil.hide()
    }

    if (template === '/services/environnement') {
      this.environnement.show(isPreloaded)
    } else {
      this.environnement.hide()
    }

    if (template === '/services/geologie') {
      this.geologie.show(isPreloaded)
    } else {
      this.geologie.hide()
    }

    if (template === '/services/sinistre') {
      this.sinistre.show(isPreloaded)
    } else {
      this.sinistre.hide()
    }

    if (template === '/nous-joindre') {
      this.joindre.show(isPreloaded)
    } else {
      this.joindre.hide()
    }

    this.template = template
  }

  onResize () {
    const breakpointsCanvas = window.innerHeight * 1.26
    /* if (window.innerHeight <= 600 && window.innerWidth >= 1024) {
      breakpointsCanvas = window.innerHeight * 1.26
      this.renderer.setSize(window.innerWidth, breakpointsCanvas)
    }
    */
    this.renderer.setSize(window.innerWidth, breakpointsCanvas)

    this.camera.perspective({
      aspect: window.innerWidth / window.innerHeight
    })

    const fov = this.camera.fov * (Math.PI / 180)
    const height = 2 * Math.tan(fov / 2) * this.camera.position.z
    const width = height * this.camera.aspect

    this.sizes = {
      height,
      width
    }

    const values = {
      sizes: this.sizes
    }

    if (this.accueil) {
      this.accueil.onResize()
    }

    if (this.environnement) {
      this.environnement.onResize(values)
    }

    if (this.geologie) {
      this.geologie.onResize(values)
    }

    if (this.joindre) {
      this.joindre.onResize(values)
    }

    if (this.sinistre) {
      this.sinistre.onResize(values)
    }
  }

  onTouchDown (event) {
    this.isDown = true

    this.x.start = event.touches ? event.touches[0].clientX : event.clientX
    this.y.start = event.touches ? event.touches[0].clientY : event.clientY

    const values = {
      x: this.x,
      y: this.y
    }

    if (this.environnement) {
      this.environnement.onTouchDown(values)
    }

    if (this.geologie) {
      this.geologie.onTouchDown(values)
    }

    if (this.joindre) {
      this.joindre.onTouchDown(values)
    }

    if (this.sinistre) {
      this.sinistre.onTouchDown(values)
    }
  }

  onTouchMove (event) {
    const x = event.touches ? event.touches[0].clientX : event.clientX
    const y = event.touches ? event.touches[0].clientY : event.clientY

    this.x.end = x
    this.y.end = y

    const values = {
      x: this.x,
      y: this.y
    }

    if (this.environnement) {
      this.environnement.onTouchMove(values)
    }

    if (this.geologie) {
      this.geologie.onTouchMove(values)
    }

    if (this.joindre) {
      this.joindre.onTouchMove(values)
    }

    if (this.sinistre) {
      this.sinistre.onTouchMove(values)
    }
  }

  onTouchUp (event) {
    this.isDown = false

    const x = event.changedTouches ? event.changedTouches[0].clientX : event.clientX
    const y = event.changedTouches ? event.changedTouches[0].clientY : event.clientY

    this.x.end = x
    this.y.end = y

    const values = {
      x: this.x,
      y: this.y
    }

    if (this.environnement) {
      this.environnement.onTouchUp(values)
    }

    if (this.geologie) {
      this.geologie.onTouchUp(values)
    }

    if (this.joindre) {
      this.joindre.onTouchUp(values)
    }

    if (this.sinistre) {
      this.sinistre.onTouchUp(values)
    }
  }

  onWheel (event) {
    if (this.environnement) {
      this.environnement.onWheel(event)
    }

    if (this.geologie) {
      this.geologie.onWheel(event)
    }

    if (this.joindre) {
      this.joindre.onWheel(event)
    }

    if (this.sinistre) {
      this.sinistre.onWheel(event)
    }
  }

  ontouchstart (event) {
    this.x.start = event.touches ? event.touches[0].clientX : event.clientX
    this.y.start = event.touches ? event.touches[0].clientY : event.clientY

    const values = {
      x: this.x,
      y: this.y
    }

    if (this.environnement) {
      this.environnement.ontouchstart(values)
    }

    if (this.geologie) {
      this.geologie.ontouchstart(values)
    }

    if (this.joindre) {
      this.joindre.ontouchstart(values)
    }

    if (this.sinistre) {
      this.sinistre.ontouchstart(values)
    }
  }

  ontouchmove (event) {
    const x = event.touches ? event.touches[0].clientX : event.clientX
    const y = event.touches ? event.touches[0].clientY : event.clientY

    this.x.end = x
    this.y.end = y

    const values = {
      x: this.x,
      y: this.y
    }

    if (this.environnement) {
      this.environnement.ontouchmove(values)
    }

    if (this.geologie) {
      this.geologie.ontouchmove(values)
    }

    if (this.joindre) {
      this.joindre.ontouchmove(values)
    }

    if (this.sinistre) {
      this.sinistre.ontouchmove(values)
    }
  }

  ontouchend (event) {
    const x = event.changedTouches ? event.changedTouches[0].clientX : event.clientX
    const y = event.changedTouches ? event.changedTouches[0].clientY : event.clientY

    this.x.end = x
    this.y.end = y

    const values = {
      x: this.x,
      y: this.y
    }

    if (this.environnement) {
      this.environnement.ontouchend(values)
    }

    if (this.geologie) {
      this.geologie.ontouchend(values)
    }

    if (this.joindre) {
      this.joindre.ontouchend(values)
    }

    if (this.sinistre) {
      this.sinistre.ontouchend(values)
    }
  }

  /**
   * Loop.
   */
  update (scroll) {
    if (this.environnement) {
      this.environnement.update()
    }

    if (this.geologie) {
      this.geologie.update()
    }

    if (this.joindre) {
      this.joindre.update()
    }

    if (this.sinistre) {
      this.sinistre.update()
    }

    this.renderer.render({
      camera: this.camera,
      scene: this.scene
    })
  }
}
