import { Plane, Transform } from 'ogl'

import GSAP from 'gsap'
import Media from './Media'
import map from 'lodash/map'

export default class {
  constructor ({ gl, scene, sizes }) {
    this.gl = gl
    this.scene = scene
    this.sizes = sizes

    this.group = new Transform()

    this.principalWrapper = document.querySelector('.geologie')
    this.galleryElement = document.querySelector('.geologie_carousel_wrapper')
    this.mediasElements = document.querySelectorAll('.geologie_carousel_media_image')

    this.resetTimeout = null

    this.x = {
      current: 0,
      target: 0,
      lerp: 0.1
    }

    this.y = {
      current: 0,
      target: 0,
      lerp: 0.1
    }

    this.scrollCurrent = {
      x: 0,
      y: 0
    }

    this.scroll = {
      x: 0,
      y: 0,
      current: 0,
      start: 0,
      target: 0,
      lerp: 0.1,
      velocity: 1
    }

    this.speed = {
      current: 0,
      target: 0,
      lerp: 0.1
    }

    this.createGeometry()
    this.createGallery()

    this.group.setParent(this.scene)

    this.show()

    setTimeout(() => {
      this.simulateMouseMove(this.scroll)
      // console.log('this.scroll envi:', this.scroll)
    }, 1000)

    // this.addEventListeners()
  }

  simulateMouseMove () {
    // Définissez les coordonnées de départs et cibles pour simuler le mouvement
    this.x.current = 0
    this.x.lerp = 0.1
    this.x.target = -10
    this.update()
  }

  createGeometry () {
    this.geometry = new Plane(this.gl, {
      widthSegments: 20,
      heightSegments: 20
    })
  }

  createGallery () {
    this.medias = map(this.mediasElements, (element, index) => {
      return new Media({
        element,
        geometry: this.geometry,
        index,
        gl: this.gl,
        scene: this.group,
        sizes: this.sizes
      })
    })
  }

  /*
  * Animations.
  */

  show () {
    this.isVisible = true
    this.group.setParent(this.scene)
    map(this.medias, media => media.show())
  }

  hide () {
    this.isVisible = false
    this.group.setParent(null)
    map(this.medias, media => media.hide())
  }

  /*
  *Events
  */

  onResize (event) {
    this.galleryBounds = this.galleryElement.getBoundingClientRect()

    this.sizes = event.sizes

    this.gallerySizes = {
      width: this.galleryBounds.width / window.innerWidth * this.sizes.width,
      height: this.galleryBounds.height / window.innerHeight * this.sizes.height
    }

    map(this.medias, media => media.onResize(event, this.scroll))
  }

  onTouchDown ({ x, y }) {
    if (!this.isVisible) return
    this.isDown = true
    this.speed.target = 0
  }

  onTouchMove ({ x, y }) {
    if (!this.isVisible) return
    if (!this.isDown) return

    const distanceX = x.end - x.start
    const distanceY = y.end - y.start

    // Mettre à jour les cibles x et y de la même manière que dans onWheel
    this.x.target += distanceX * 0.05
    this.y.target += distanceY * 0.05
    this.speed.target = 1
  }

  onTouchUp ({ x, y }) {
    if (!this.isVisible) return
    this.isDown = false
    this.speed.target = 0
  }

  onWheel (event) {
    if (!this.isVisible) return
    this.x.target += event.pixelY * 0.5
    this.y.target += event.pixelY * 0.5

    this.speed.target = 1
    this.scrollCurrent.x = this.scroll.x
    this.scrollCurrent.y = this.scroll.y
    // Réinitialisez les transformations après un délai sans défilement
    clearTimeout(this.resetTimeout)
    this.resetTimeout = setTimeout(() => {
      this.speed.target = 0
    }, 200) // Ajustez la durée du délai
  }

  ontouchstart (event) {
    if (!this.isVisible) return
    this.isDown = true
    this.speed.target = 1
  }

  ontouchmove (event) {
    if (!this.isVisible) return
    if (!this.isDown) return
    this.x.target += (event.y.start - event.y.end) * 1
    this.y.target += (event.y.end - event.y.start) * 0.05
    this.speed.target = 1
  }

  ontouchend (event) {
    if (!this.isVisible) return
    this.isDown = false
    this.speed.target = 0
  }

  /*
  *Loop
  */
  update () {
    if (!this.galleryBounds) return

    this.speed.current = GSAP.utils.interpolate(this.speed.current, this.speed.target, this.speed.lerp)

    this.x.current = GSAP.utils.interpolate(this.x.current, this.x.target, this.x.lerp)
    this.y.current = GSAP.utils.interpolate(this.y.current, this.y.target, this.y.lerp)

    if (this.scroll.x < this.x.current) {
      this.x.direction = 'right'
    } else if (this.scroll.x > this.x.current) {
      this.x.direction = 'left'
    }

    if (this.scroll.y < this.y.current) {
      this.y.direction = 'top'
    } else if (this.scroll.y > this.y.current) {
      this.y.direction = 'bottom'
    }

    this.scroll.x = this.x.current
    this.scroll.y = this.y.current

    map(this.medias, (media, index) => {
      const scaleX = media.mesh.scale.x / 2
      const scaleY = media.mesh.scale.y / 2

      const offsetY = this.sizes.height / 2
      const offsetX = this.sizes.width / 2

      if (this.x.direction === 'left') {
        const x = media.mesh.position.x + scaleX
        if (x < -offsetX) {
          media.extra.x += this.gallerySizes.width * 1.67
        }
      } else if (this.x.direction === 'right') {
        const x = media.mesh.position.x - scaleX
        if (x > offsetX) {
          media.extra.x -= this.gallerySizes.width * 1.67
        }
      }

      if (this.y.direction === 'top') {
        const y = media.mesh.position.y + scaleY
        if (y < -offsetY) {
          media.extra.y += this.gallerySizes.height * 5.5
        }
      } else if (this.y.direction === 'bottom') {
        const y = media.mesh.position.y - scaleY
        if (y > offsetY) {
          media.extra.y -= this.gallerySizes.height * 5.5
        }
      }
      media.update(this.scroll, this.speed.current)
    })
  }

  /*
  ** Destroy
  */

  destroy () {
    this.scene.removeChild(this.group)
  }
}
