// import GSAP from 'gsap'
import Component from 'classes/Component'

import { mapEach } from 'utils/dom'
import AsyncLoad from 'classes/AsyncLoad'

export default class Menu extends Component {
  constructor () {
    super({
      element: '.menu',
      elements: {
        images: '[data-src]'
      }
    })
    this.ImageLoaded()
  }

  /**
   * chargement images.
   */
  ImageLoaded () {
    this.images = mapEach(this.elements.images, element => {
      return new AsyncLoad({
        element
      })
    })
  }
}
